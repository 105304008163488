export class Reservation {
  constructor(reservation) {
    this.id = reservation.id
    this.startDate = reservation.startDate
    this.expiringDate = reservation.expiringDate
    this.expiringDateAux = reservation.expiringDateAux
    this.status = reservation.status
    this.roomType = reservation.roomType
    this.user = reservation.user
    this.guests = reservation.guests
    this.buildingId = reservation.buildingId
    this.buildingName = reservation.buildingName
    this.slots = reservation.slots
    this.numeroContratto = reservation.numeroContratto
    this.depositAmount = reservation.depositAmount
    this.monthlyAmount = reservation.monthlyAmount
    this.serviceFees = reservation.serviceFees
    this.totalAmount = reservation.totalAmount
    this.apartment = reservation.apartment
    this.room = reservation.room
    this.contractAccepted = reservation.contractAccepted
    this.cardDetails = reservation.cardDetails
    this.documents = reservation.documents
    this.payments = reservation.payments
    this.tickets = reservation.tickets
    reservation.room !== null ? this.name = reservation.room.name : reservation.apartment !== null ? this.name = reservation.apartment.name : this.name = ""
    this.firstname = reservation.user.firstname
    this.lastname = reservation.user.lastname
    this.email = reservation.user.email
    this.availableToPay = reservation.availableToPay
    this.creditCardManualPayments = reservation.creditCardManualPayments
  }
}
